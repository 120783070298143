import { useState, useEffect } from "react";
import {
  PdfViewerComponent,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { useSelector } from "react-redux";
import axios from "axios";
import { selectCurrentToken } from "../../features/auth/authSlice";
import { toast, Toaster } from "react-hot-toast";

const PDFViewer = ({ invoiceId, height }) => {
  const token = useSelector(selectCurrentToken);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    const getFile = async (id) => {
      try {
        const response = await axios.get(
          `${
            window.location.hostname === "localhost"
              ? process.env.REACT_APP_API_URL
              : process.env.PUBLIC_URL
          }/v1/invoices/${id}/file`,
          {
            headers: {
              responseType: "arrayBuffer",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );
        const base64String = await blobToBase64(response.data);
        setFile(base64String);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to fetch the PDF document. " + error?.message);
        setLoading(false);
      }
    };
    getFile(invoiceId);
  }, [invoiceId, token]);

  const handleDocumentLoadFailed = (event) => {
    toast.error("Failed to load the PDF document:", event);

    if (event.reason && event.reason.toLowerCase() === "network error") {
      toast.error(
        "Failed to fetch the PDF document. Check your internet connection or the PDF file path."
      );
    } else {
      toast.error(
        "An error occurred while loading the PDF document. Please try again later."
      );
    }
    setLoading(false);
  };

  return (
    <div className="control-section">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {loading ? (
        <p>Loading PDF...</p>
      ) : file ? (
        <PdfViewerComponent
          documentPath={file}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
          documentLoadFailed={handleDocumentLoadFailed}
          height={height}
        >
          <Inject
            services={[
              Magnification,
              Navigation,
              Annotation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              FormFields,
              FormDesigner,
            ]}
          />
        </PdfViewerComponent>
      ) : (
        <p>Failed to load PDF document.</p>
      )}
    </div>
  );
};

export default PDFViewer;
