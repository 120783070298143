import React from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const NoPermisson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/login");
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="text-center">
        <h1 className="display-4 text-danger">Access Denied</h1>
        <p className="lead">
          You don't have permission to access any of the Services.
        </p>
        <p>Please contact your administrator for further assistance.</p>
        <button className="btn btn-blue mt-3 me-3" onClick={handleLogout}>
          Logout
        </button>
        <button className="btn btn-secondary mt-3" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
    </div>
  );
};

export default NoPermisson;
