import React, { useState, useEffect } from "react";
import "./ApprovalRules.css";
import { nanoid } from "@reduxjs/toolkit";
import addIcon from "../../assets/images/Icons/addIcon.png";
import {
  useGetApprovalRulesFieldsQuery,
  useGetApprovalRulesQuery,
} from "./approvalRulesSlice";
import Rule from "../../components/ApprovalRules/Rule/Rule";
import { PagerComponent } from "@syncfusion/ej2-react-grids";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";
import { useErrorToast } from "../../hooks/useErrorToast";

const ApprovalRules = () => {
  const [tableColumns, setTableColumns] = useState([]);
  const [rules, setRules] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRules, setCurrentRules] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const pageSize = 4;

  const showErrorToast = useErrorToast();

  const {
    data: fieldsData,
    isLoading: fieldsIsLoading,
    isSuccess: fieldsIsSuccess,
    isError: fieldsIsError,
    error: fieldsError,
  } = useGetApprovalRulesFieldsQuery();
  const {
    data: rulesData,
    isLoading: rulesIsLoading,
    isSuccess: rulesIsSuccess,
    isError: rulesIsError,
    error: rulesError,
  } = useGetApprovalRulesQuery();

  useEffect(() => {
    if (fieldsData) {
      const data = fieldsData.ids.map((id) => fieldsData.entities[id]);
      setTableColumns(data);
    }
    if (fieldsIsError) {
      showErrorToast(fieldsError);
    }
  }, [fieldsData, fieldsError]);

  useEffect(() => {
    if (rulesData) {
      const data = rulesData.ids.map((id) => rulesData.entities[id]);
      setRules(data);
    }
    if (rulesIsError) {
      showErrorToast(rulesError);
    }
  }, [rulesData, rulesError]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentRules(rules.slice(startIndex, endIndex));
  }, [currentPage, rules]);

  const generateNewRule = () => {
    setCurrentPage(1);
    const newRule = {
      guid: nanoid(),
      description: "",
      field: {
        id: "",
        name: "",
        type: "varchar",
      },
      condition: "==",
      value: "",
      isNew: true,
    };
    setRules((prevRules) => [newRule, ...prevRules]);
  };

  const removeRule = (id) => {
    setRules((prevRules) => prevRules.filter((rule) => rule.guid !== id));
  };

  const handlePagerClick = (page) => {
    setCurrentPage(page.currentPage);
  };

  return (
    <div className="approval-rules">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {/* <h4>Rules</h4> */}
      <div className="custom-card">
        <div className="mb-4">
          <button className="btn btn-add-invoice" onClick={generateNewRule}>
            Add Rule
            <img
              src={addIcon}
              width={18}
              alt="add icon"
              className="pb-1 ms-2"
            />
          </button>
        </div>
        <div className="rule-section">
          {rulesIsLoading && !rulesIsError && <LoadingSpinner />}
          {rulesIsSuccess &&
          fieldsIsSuccess &&
          tableColumns.length > 0 &&
          currentRules.length > 0 ? (
            currentRules.map((rule, index) => (
              <Rule
                key={rule.guid}
                rule={rule}
                columns={tableColumns}
                ruleNumber={rule?.ruleNumber}
                removeRule={removeRule}
              />
            ))
          ) : (
            <p>No rules available</p>
          )}
        </div>
        {rules.length > 0 && tableColumns.length > 0 && (
          <PagerComponent
            pageCount={Math.ceil(rules.length / pageSize)}
            pageSize={pageSize}
            currentPage={currentPage}
            totalRecordsCount={rules.length}
            click={handlePagerClick}
          />
        )}
      </div>
    </div>
  );
};

export default ApprovalRules;
