import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import searchIcon from "../../../assets/images/Icons/search.png";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  useGetProcessingStatusesQuery,
  useGetReviewStatusesQuery,
  useGetReviewTypesQuery,
} from "../../../features/InvoiceManager/invoiceSlice";

import "./FilterModal.css";
import { format } from "date-fns";
import { useGetUsersQuery } from "../../../features/Users/UsersSlice";
import toast from "react-hot-toast";
import { useErrorToast } from "../../../hooks/useErrorToast";

const FilterModal = ({ isOpen, toggle, handleFilter, type = "all" }) => {
  const {
    data: processingStatuses,
    isLoading: processingStatusesIsLoading,
    isSuccess: ProcessingStatusesIsSuccess,
  } = useGetProcessingStatusesQuery();
  const {
    data: reviewStatuses,
    isLoading: reviewStatusesIsLoading,
    isSuccess: reviewStatusesIsSuccess,
  } = useGetReviewStatusesQuery();
  const {
    data: reviewTypes,
    isLoading: reviewTypesIsLoading,
    isSuccess: reviewTypesIsSuccess,
  } = useGetReviewTypesQuery();
  const {
    data: usersData,
    isLoading: isUsersDataLoading,
    isFetching: isUsersDataFetching,
    isSuccess: isUsersDataSuccess,
    isError: isUsersDataError,
    error: usersDataError,
  } = useGetUsersQuery();

  const showErrorToast = useErrorToast();

  useEffect(() => {
    if (usersData) {
      const data = usersData?.ids?.map((id) => usersData?.entities[id]);
      setUsers(data);
    }
    if (isUsersDataError) {
      showErrorToast(usersDataError);
    }
  }, [usersData, isUsersDataError]);

  const formRef = useRef(null);

  const [users, setUsers] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(
    JSON.parse(
      localStorage.getItem(
        type === "my" ? "mySelectedStatus" : "selectedStatus"
      )
    ) || {
      reviewStatus: {},
      processStatus: {},
    }
  );

  const [selectedAssigneeId, setSelectedAssigneeId] = useState("");

  const [createdOnGreaterThan, setCreatedOnGreaterThan] = useState(
    JSON.parse(
      localStorage.getItem(
        type === "my" ? "myCreatedOnGreaterThan" : "createdOnGreaterThan"
      )
    )
  );
  const [createdOnLessThan, setCreatedOnLessThan] = useState(
    JSON.parse(
      localStorage.getItem(
        type === "my" ? "myCreatedOnLessThan" : "createdOnLessThan"
      )
    )
  );
  const [updatedOnGreaterThan, setUpdatedOnGreaterThan] = useState(
    JSON.parse(
      localStorage.getItem(
        type === "my" ? "myUpdatedOnGreaterThan" : "updatedOnGreaterThan"
      )
    )
  );
  const [updatedOnLessThan, setUpdatedOnLessThan] = useState(
    JSON.parse(
      localStorage.getItem(
        type === "my" ? "myUpdatedOnLessThan" : "updatedOnLessThan"
      )
    )
  );
  const [totalGreaterThan, setTotalGreaterThan] = useState(
    localStorage.getItem(
      type === "my" ? "myTotalGreaterThan" : "totalGreaterThan"
    ) || ""
  );
  const [totalLessThan, setTotalLessThan] = useState(
    localStorage.getItem(type === "my" ? "myTotalLessThan" : "totalLessThan") ||
      ""
  );

  const [assignedToId, setAssignedToId] = useState(
    JSON.parse(
      localStorage.getItem(type === "my" ? "myAssignedToId" : "assignedToId")
    ) || null
  );

  useEffect(() => {
    if (selectedAssigneeId) {
      const assignee = users?.find(
        (user) => user.id === parseInt(selectedAssigneeId)
      );
      if (assignee) {
        setAssignedToId(assignee);
        localStorage.setItem(
          type === "my" ? "myAssignedToId" : "assignedToId",
          JSON.stringify(assignee)
        );
      }
    }
  }, [selectedAssigneeId]);

  useEffect(() => {
    if (assignedToId) {
      setSelectedAssigneeId(assignedToId?.id);
    }
  }, [assignedToId]);

  useEffect(() => {
    localStorage.setItem(
      type === "my" ? "mySelectedStatus" : "selectedStatus",
      JSON.stringify(selectedStatus)
    );
    localStorage.setItem(
      type === "my" ? "myCreatedOnGreaterThan" : "createdOnGreaterThan",
      JSON.stringify(createdOnGreaterThan)
    );
    localStorage.setItem(
      type === "my" ? "myCreatedOnLessThan" : "createdOnLessThan",
      JSON.stringify(createdOnLessThan)
    );
    localStorage.setItem(
      type === "my" ? "myUpdatedOnGreaterThan" : "updatedOnGreaterThan",
      JSON.stringify(updatedOnGreaterThan)
    );
    localStorage.setItem(
      type === "my" ? "myUpdatedOnLessThan" : "updatedOnLessThan",
      JSON.stringify(updatedOnLessThan)
    );
    localStorage.setItem(
      type === "my" ? "myTotalGreaterThan" : "totalGreaterThan",
      totalGreaterThan
    );
    localStorage.setItem(
      type === "my" ? "myTotalLessThan" : "totalLessThan",
      totalLessThan
    );
  }, [
    selectedStatus,
    createdOnGreaterThan,
    createdOnLessThan,
    updatedOnGreaterThan,
    updatedOnLessThan,
    totalGreaterThan,
    totalLessThan,
  ]);

  const handleCheckboxChange = (statusType, statusId, checked) => {
    setSelectedStatus((prevStatus) => ({
      ...prevStatus,
      [statusType]: {
        ...prevStatus[statusType],
        [statusId]: checked,
      },
    }));
  };

  const generateQueryString = () => {
    const reviewStatusIds = Object.entries(selectedStatus.reviewStatus)
      .filter(([_, checked]) => checked)
      .map(([id]) => `ReviewStatusId=${id}`)
      .join("&");
    const processStatusIds = Object.entries(selectedStatus.processStatus)
      .filter(([_, checked]) => checked)
      .map(([id]) => `ProcessingStatusId=${id}`)
      .join("&");
    let queryString = [reviewStatusIds, processStatusIds]
      .filter(Boolean)
      .join("&");

    if (createdOnGreaterThan)
      queryString += `&CreatedOnGreaterThan=${createdOnGreaterThan}`;
    if (createdOnLessThan)
      queryString += `&CreatedOnLessThan=${createdOnLessThan}`;
    if (updatedOnGreaterThan)
      queryString += `&UpdatedOnGreaterThan=${updatedOnGreaterThan}`;
    if (updatedOnLessThan)
      queryString += `&UpdatedOnLessThan=${updatedOnLessThan}`;

    if (totalGreaterThan)
      queryString += `&TotalGreaterThan=${totalGreaterThan}`;
    if (totalLessThan) queryString += `&TotalLessThan=${totalLessThan}`;
    if (assignedToId) queryString += `&AssignedToId=${assignedToId?.id}`;

    return queryString;
  };

  const [reviewStatus, setReviewStatus] = useState([]);
  const [processStatus, setProcessStatus] = useState([]);

  useEffect(() => {
    if (processingStatuses) {
      const data = processingStatuses.ids.map(
        (id) => processingStatuses.entities[id]
      );
      setProcessStatus(data);
    }
    if (reviewStatuses) {
      const data = reviewStatuses.ids.map((id) => reviewStatuses.entities[id]);
      setReviewStatus(data);
    }
  }, [processingStatuses, reviewStatuses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedFilters = {
      reviewStatuses: reviewStatus
        ? reviewStatus.filter((obj) =>
            Object.keys(selectedStatus.reviewStatus)
              .filter((key) => selectedStatus.reviewStatus[key])
              .map((item) => parseInt(item))
              .includes(obj.id)
          )
        : [],
      processStatuses: processStatus
        ? processStatus.filter((obj) =>
            Object.keys(selectedStatus.processStatus)
              .filter((key) => selectedStatus.processStatus[key])
              .map((item) => parseInt(item))
              .includes(obj.id)
          )
        : [],
      totalLessThan,
      totalGreaterThan,
      updatedOnLessThan,
      updatedOnGreaterThan,
      createdOnLessThan,
      createdOnGreaterThan,
      assignedToId,
    };

    handleFilter(generateQueryString(), appliedFilters);
  };

  const handleReset = () => {
    setSelectedStatus({
      reviewStatus: {},
      processStatus: {},
    });

    setCreatedOnGreaterThan(null);
    setCreatedOnLessThan(null);
    setUpdatedOnGreaterThan(null);
    setUpdatedOnLessThan(null);
    setTotalGreaterThan("");
    setTotalLessThan("");
    setAssignedToId(null);
    setSelectedAssigneeId("");

    formRef.current.reset();
    handleFilter("");
    localStorage.removeItem(type === "my" ? "myFilterObj" : "filterObj");
    localStorage.removeItem(
      type === "my" ? "myAppliedFilters" : "appliedFilters"
    );
    localStorage.removeItem(type === "my" ? "myAssignedToId" : "assignedToId");
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="top-right-modal">
      <ModalHeader
        toggle={toggle}
        style={{ borderBottom: "none", paddingBottom: "0" }}
      >
        Filter
      </ModalHeader>
      <form className="container" onSubmit={handleSubmit} ref={formRef}>
        <ModalBody>
          <div className="row mb-1">
            <div className="col">
              <span className="filter-title">Review Status</span>
              {reviewStatus.map(({ name, id }) => (
                <div className="form-check" key={id}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={name}
                    checked={selectedStatus.reviewStatus[id] || false}
                    onChange={(e) =>
                      handleCheckboxChange("reviewStatus", id, e.target.checked)
                    }
                  />
                  <label className="form-check-label">{name}</label>
                </div>
              ))}
            </div>
            <div className="col">
              <span className="filter-title">Processing Status</span>
              {processStatus.map(({ name, id }) => (
                <div className="form-check" key={id}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={name}
                    checked={selectedStatus.processStatus[id] || false}
                    onChange={(e) =>
                      handleCheckboxChange(
                        "processStatus",
                        id,
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label">{name}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="row g-2 mb-2">
            <span className="filter-title">Invoices Created On</span>
            <div className="col-6">
              After
              <div className="input-group">
                <DatePickerComponent
                  cleared={() => setCreatedOnGreaterThan(null)}
                  id="CreatedOnGreaterThan"
                  value={createdOnGreaterThan}
                  format="dd-MM-yyyy"
                  placeholder="dd-mm-yyyy"
                  onChange={(e) => {
                    setCreatedOnGreaterThan(
                      format(new Date(e.target.value), "yyyy/MM/dd")
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              Before
              <div className="input-group">
                <DatePickerComponent
                  cleared={() => setCreatedOnLessThan(null)}
                  id="CreatedOnLessThan"
                  value={createdOnLessThan}
                  format="dd-MM-yyyy"
                  placeholder="dd-mm-yyyy"
                  onChange={(e) =>
                    setCreatedOnLessThan(
                      format(new Date(e.target.value), "yyyy/MM/dd")
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-2 mb-2">
            <span className="filter-title">Invoices Updated On</span>
            <div className="col-6">
              After
              <div className="input-group">
                <DatePickerComponent
                  cleared={() => setUpdatedOnGreaterThan(null)}
                  id="UpdatedOnGreaterThan"
                  value={updatedOnGreaterThan}
                  format="dd-MM-yyyy"
                  placeholder="dd-mm-yyyy"
                  onChange={(e) =>
                    setUpdatedOnGreaterThan(
                      format(new Date(e.target.value), "yyyy/MM/dd")
                    )
                  }
                />
              </div>
            </div>
            <div className="col-6">
              Before
              <div className="input-group">
                <DatePickerComponent
                  cleared={() => setUpdatedOnLessThan(null)}
                  id="UpdatedOnLessThan"
                  value={updatedOnLessThan}
                  format="dd-MM-yyyy"
                  placeholder="dd-mm-yyyy"
                  onChange={(e) =>
                    setUpdatedOnLessThan(
                      format(new Date(e.target.value), "yyyy/MM/dd")
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-2 mb-2">
            <span className="filter-title">Total Amount</span>
            <div className="col-6">
              Min
              <div className="input-group">
                <label
                  htmlFor="TotalGreaterThan"
                  className="input-group-text search-icon-container"
                >
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="icon-image"
                    width={12}
                  />
                </label>
                <input
                  id="TotalGreaterThan"
                  type="number"
                  min={0}
                  className="form-control invoice-search"
                  placeholder="£0"
                  value={totalGreaterThan}
                  onChange={(e) => setTotalGreaterThan(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              Max
              <div className="input-group">
                <label
                  htmlFor="TotalLessThan"
                  className="input-group-text search-icon-container"
                >
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="icon-image"
                    width={12}
                  />
                </label>
                <input
                  id="TotalLessThan"
                  type="number"
                  min={0}
                  className="form-control invoice-search"
                  placeholder="£0"
                  value={totalLessThan}
                  onChange={(e) => setTotalLessThan(e.target.value)}
                />
              </div>
            </div>
          </div>
          {type !== "my" && (
            <div className="row g-2 mb-2">
              <div className="col-6">
                <span className="filter-title">Assigned To</span>
                <div className="input-group mb-2">
                  <select
                    value={assignedToId?.id}
                    className="form-select invoice-search"
                    id="floatingSelect"
                    onChange={(e) => setSelectedAssigneeId(e.target.value)}
                  >
                    <option value=""></option>
                    {users?.map((user) => (
                      <option key={user?.id} value={user?.id}>
                        {user?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter style={{ borderTop: "none" }} className="row">
          <div className="col">
            <Button
              type="reset"
              className="btn-reset w-100"
              onClick={handleReset}
            >
              Reset Filters
            </Button>
          </div>
          <div className="col">
            <Button type="submit" className="btn-filter w-100">
              Apply Filters
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FilterModal;
