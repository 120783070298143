import React, { useEffect, useRef, useState } from "react";
import {
  RichTextEditorComponent,
  Inject,
  Toolbar,
  Image,
  Link,
  HtmlEditor,
  QuickToolbar,
  NodeSelection,
} from "@syncfusion/ej2-react-richtexteditor";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import axios from "axios";
import { getBaseUrl } from "../common/utils/utils";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import toast from "react-hot-toast";
import { useErrorToast } from "../../hooks/useErrorToast";

const CommentSection = ({ handleAddComment, comment }) => {
  const [commentDescription, setCommentDescription] = useState("");
  const rteObj = useRef(null);
  const uploadObj = useRef(null);
  const showErrorToast = useErrorToast();

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const jwtToken = useSelector(selectCurrentToken);

  const dropElement = ".e-richtexteditor";
  const url = `${getBaseUrl()}attachments`;

  let savedSelection = null;
  const selection = new NodeSelection();

  useEffect(() => {
    if (comment) {
      rteObj.current.value = comment;
    }
  }, [comment]);

  const saveSelection = () => {
    if (rteObj.current) {
      savedSelection = rteObj.current.getSelection();
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const responsePromise = axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.promise(responsePromise, {
        loading: "Uploading...",
        success: "File Saved...!",
        error: "Something Went Wrong...!",
      });

      const response = await responsePromise;
      const guid = response.data.guid;

      const fileUrl = `${url}/${guid}`;
      return fileUrl;
    } catch (error) {
      uploadObj.current.clearAll();
      showErrorToast(error?.response);
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const onFileSelected = async (args) => {
    saveSelection();

    const file = args.filesData[0].rawFile;
    const fileUrl = await uploadFile(file);

    if (fileUrl) {
      rteObj.current.focusIn();
      const range = rteObj.current.getRange();
      savedSelection = selection.save(range, document);

      if (rteObj.current.formatter.getUndoRedoStack().length === 0) {
        rteObj.current.formatter.saveData();
      }
      savedSelection.restore();
      rteObj.current.executeCommand("createLink", {
        url: fileUrl,
        text: `📁 ${file?.name ?? "file"}`,
        selection: savedSelection,
      });

      const createdLink = rteObj.current.contentModule
        .getEditPanel()
        .querySelector('a[href="' + fileUrl + '"]');
      if (createdLink) {
        createdLink.setAttribute("target", "_blank");
      }

      rteObj.current.formatter.saveData();
      rteObj.current.formatter.enableUndo(rteObj.current);
      uploadObj.current.clearAll();
    } else {
      console.error("Failed to upload the file");
    }
  };

  const addComment = () => {
    if (!commentDescription) {
      toast.error("Comment cannot be empty...!");
      return;
    }
    handleAddComment(commentDescription);
  };

  const handleClear = () => {
    setCommentDescription("");
    rteObj.current.value = "";
  };

  return (
    <>
      <RichTextEditorComponent
        id="comments"
        height={300}
        toolbarSettings={toolbarSettings}
        quickToolbarSettings={{
          image: ["Replace", "Align", "Caption", "Remove"],
        }}
        ref={rteObj}
        change={(args) => {
          setCommentDescription(args.value);
        }}
      >
        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
      </RichTextEditorComponent>

      <UploaderComponent
        id="fileUpload"
        type="file"
        ref={uploadObj}
        dropArea={dropElement}
        selected={onFileSelected}
        uploading={saveSelection}
        buttons={{
          browse: "📁 Upload Attachment",
        }}
      />
      <div className="d-flex justify-content-end p-1">
        <button
          className="btn btn-grey btn-sm h-100 me-1"
          onClick={handleClear}
        >
          Clear
        </button>
        <button className="btn btn-blue btn-sm h-100" onClick={addComment}>
          Comment
        </button>
      </div>
    </>
  );
};

export default CommentSection;
