import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    user: JSON.parse(localStorage.getItem("user")),
    permissions:
      JSON.parse(localStorage.getItem("user"))?.modules?.find(
        (module) => module?.guid === "790af7b9-d9f8-4862-abf7-c0afeef174e8"
      )?.permissions || [],
  },
  reducers: {
    setCredentials: (state, action) => {
      const { token, user } = action.payload;
      const permissions = user?.modules.find(
        (module) => module.guid === "790af7b9-d9f8-4862-abf7-c0afeef174e8"
      )?.permissions;
      state.token = token;
      state.user = user;
      state.permissions = permissions;
    },

    logOut: (state, action) => {
      state.token = null;
      state.user = null;
      state.permissions = null;
      localStorage.clear();
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentPermissions = (state) => state.auth.permissions;
