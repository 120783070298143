import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { format, parseISO } from "date-fns";
import { currencyFormatter } from "../../components/common/utils/utils";

const approvalRequiredinvoiceAdapter = createEntityAdapter({
  selectId: (invoice) => invoice.guid,
});

const requireToApprovalinvoiceAdapter = createEntityAdapter({
  selectId: (invoice) => invoice.guid,
});

const approvalRequiredInvoiceInitialState =
  approvalRequiredinvoiceAdapter.getInitialState();
const requireToApprovalInvoiceInitialState =
  requireToApprovalinvoiceAdapter.getInitialState();

export const approvalRequireInvoiceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApprovalRequireInvoices: builder.query({
      query: ({ statusId }) => `invoices?ReviewStatusId=${statusId}`,
      transformResponse: (responseData) => {
        const loadedInvoiceDetails = responseData?.items?.map((item) => {
          const { data } = item;
          const invoiceNumber =
            data.find((field) => field.fieldName === "Invoice Number")
              ?.fieldValue || "N/A";
          const totalAmount =
            data.find((field) => field.fieldName === "Total Amount")
              ?.fieldValue || "N/A";
          return {
            ...item,
            invoiceNumber,
            totalAmount,
          };
        });
        return approvalRequiredinvoiceAdapter.setAll(
          approvalRequiredInvoiceInitialState,
          loadedInvoiceDetails
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "approvalRequireInvoices", id: "LIST" }];
        } else {
          return [
            { type: "approvalRequireInvoices", id: "LIST" },
            { type: "InvoiceDetail", id: "LIST" },
            { type: "Invoices", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "approvalRequireInvoices",
              id,
            })),
          ];
        }
      },
    }),
    getRequireToApprovalInvoices: builder.query({
      query: ({
        page,
        pageSize,
        totalGreaterThan,
        totalLessThan,
        query,
        assignedToId,
        createdById,
        updatedById,
        updatedOnLessThan,
        updatedOnGreaterThan,
        createdOnLessThan,
        createdOnGreaterThan,
        reviewTypeId,
        processingStatusId,
        reviewStatusId,
      }) =>
        reviewTypeId && processingStatusId && reviewStatusId
          ? `invoices/to-approve?page=${page}&pageSize=${pageSize}&TotalGreaterThan=${totalGreaterThan}&TotalLessThan=${totalLessThan}&Query=${query}&AssignedToId=${assignedToId}&CreatedById=${createdById}&UpdatedById=${updatedById}&UpdatedOnLessThan=${updatedOnLessThan}&UpdatedOnGreaterThan=${updatedOnGreaterThan}&CreatedOnLessThan=${createdOnLessThan}&CreatedOnGreaterThan=${createdOnGreaterThan}&ReviewTypeId=${reviewTypeId}&ProcessingStatusId=${processingStatusId}&ReviewStatusId=${reviewStatusId}`
          : `invoices/to-approve?page=${page}&pageSize=${pageSize}&TotalGreaterThan=${totalGreaterThan}&TotalLessThan=${totalLessThan}&Query=${query}&AssignedToId=${assignedToId}&CreatedById=${createdById}&UpdatedById=${updatedById}&UpdatedOnLessThan=${updatedOnLessThan}&UpdatedOnGreaterThan=${updatedOnGreaterThan}&CreatedOnLessThan=${createdOnLessThan}&CreatedOnGreaterThan=${createdOnGreaterThan}`,
      transformResponse: (responseData) => {
        const loadedRequireToApprovalInvoiceDetails = responseData?.items.map(
          (item) => {
            const { data } = item;
            const invoiceNumber =
              data.find((field) => field.fieldName === "Invoice Number")
                ?.fieldValue || "N/A";
            const totalAmount =
              data.find((field) => field.fieldName === "Total Amount")
                ?.fieldValue || "N/A";
            return {
              ...item,
              invoiceNumber,
              totalAmount,
              pageSettings: {
                pageCount: responseData?.totalPages,
                pageSize: responseData?.pageSize,
                totalRecordsCount: responseData?.total,
                currentPage: responseData?.page,
              },
            };
          }
        );
        return approvalRequiredinvoiceAdapter.setAll(
          requireToApprovalInvoiceInitialState,
          loadedRequireToApprovalInvoiceDetails
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "requireToApproval", id: "LIST" }];
        } else {
          return [
            { type: "requireToApproval", id: "LIST" },
            { type: "InvoiceDetail", id: "LIST" },
            { type: "Invoices", id: "LIST" },
            ...result.ids.map((id) => ({ type: "requireToApproval", id })),
          ];
        }
      },
    }),
  }),
});

export const {
  useGetApprovalRequireInvoicesQuery,
  useGetRequireToApprovalInvoicesQuery,
} = approvalRequireInvoiceSlice;
