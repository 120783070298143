import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useAssignInvoiceMutation } from "../../features/InvoiceManager/invoiceSlice";
import { useGetUsersQuery } from "../../features/Users/UsersSlice";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useErrorToast } from "../../hooks/useErrorToast";
const InvoiceAssigneeModal = ({
  isOpen,
  toggleModal,
  resetAction,
  invoiceGuid,
  pageName = "",
}) => {
  const [users, setUsers] = useState([]);
  const [userGuid, setUserGuid] = useState("");

  const user = useSelector(selectCurrentUser);
  const showErrorToast = useErrorToast();

  const {
    data: usersData,
    isLoading: isUsersDataLoading,
    isFetching: isUsersDataFetching,
    isSuccess: isUsersDataSuccess,
    isError: isUsersDataError,
    error: usersDataError,
  } = useGetUsersQuery();

  const [
    assignInvoice,
    {
      isLoading: assignInvoiceIsLoading,
      isSuccess: assignInvoicesSuccess,
      isError: assignInvoiceIsError,
    },
  ] = useAssignInvoiceMutation();

  useEffect(() => {
    if (usersData) {
      const data = usersData?.ids?.map((id) => usersData?.entities[id]);
      if (pageName === "myInvoices") {
        setUsers(data?.filter((d) => d.guid !== user?.guid));
      } else {
        setUsers(data);
      }
    }
    if (isUsersDataError) {
      showErrorToast(usersDataError);
    }
  }, [usersData, isUsersDataError]);

  const handleAssignTo = async (e) => {
    e.preventDefault();
    try {
      await assignInvoice({
        guid: invoiceGuid,
        userGuid,
      }).unwrap();
      toggleModal();
      resetAction();
      setUserGuid("");
      toast.success("Invoice successfully assigned");
    } catch (error) {
      showErrorToast(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      centered
      size="sm"
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          toggleModal();
          resetAction();
        }}
      >
        Select Assignee
      </ModalHeader>
      <ModalBody>
        <div>
          <form onSubmit={handleAssignTo}>
            <div className="form-floating mb-2">
              <select
                value={userGuid}
                className="form-select"
                id="floatingSelect"
                required
                onChange={(e) => setUserGuid(e.target.value)}
              >
                <option value=""></option>
                {users?.map((user) => (
                  <option key={user.id} value={user.guid}>
                    {user?.name}
                  </option>
                ))}
              </select>
              <label htmlFor="floatingSelect">Select an Assignee</label>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-blue btn-sm w-100 h-100"
                disabled={assignInvoiceIsLoading}
              >
                Assign
              </button>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InvoiceAssigneeModal;
