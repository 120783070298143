import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LoginPage.css";
import logo from "../../assets/images/logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentToken,
  setCredentials,
} from "../../features/auth/authSlice";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";
import { authTypes } from "../../enums/authTypes";
import { getAuthURL } from "../../components/common/utils/utils";
import { userPermissions } from "../../enums/userPermissons";

const LoginPage = () => {
  const navigate = useNavigate();
  const currentToken = useSelector(selectCurrentToken);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const authURL = getAuthURL();
      try {
        if (!currentToken) {
          const token = getTokenFromURL();
          if (token) {
            const user = await getUserDetails(token);
            if (user) {
              dispatch(setCredentials({ token, user }));
              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(user));
              window.history.replaceState(null, null, window.location.pathname);
              const redirectPath = localStorage.getItem("redirectPath") || path;
              const path = getFirstRoutesUserHasPermission(user, redirectPath);
              navigate(path);
            } else {
              handleLoginError(
                "Something went wrong while getting User Details..."
              );
            }
          } else {
            localStorage.setItem(
              "redirectPath",
              location.state?.from?.pathname + location.state?.from?.search ||
                "/my-invoices"
            );
            window.location.href = `${authURL}/#/portal?continue=${window.location.origin}?authtype=${authTypes.FALSE}`;
          }
        }
      } catch (error) {
        handleLoginError("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [currentToken, dispatch, navigate, location]);

  const getTokenFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("token");
  };

  const getUserDetails = async (token) => {
    const authURL = getAuthURL();
    const tokenData = jwtDecode(token);
    const guid = tokenData?.sub;
    const response = await axios.get(`${authURL}/v1/companies/users/${guid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const refreshTokenHandler = async () => {
    const authURL = getAuthURL();
    try {
      const refreshTokenResponse = await axios.post(
        `${authURL}/v1/auth/tokens:refresh`,
        null,
        {
          withCredentials: true,
        }
      );
      const newToken = refreshTokenResponse.data.token;
      const user = await getUserDetails(newToken);
      dispatch(setCredentials({ token: newToken, user }));
      window.history.replaceState(null, null, window.location.pathname);
      const redirectPath =
        localStorage.getItem("redirectPath") || "/my-invoices";
      navigate(redirectPath);
    } catch (refreshError) {
      console.log("Error refreshing token:", refreshError);
      window.location.href = `${authURL}/#/portal?continue=${window.location.origin}?authtype=${authTypes.TRUE}`;
    }
  };

  const handleLoginError = (message, error) => {
    if (error?.response?.status === 401) {
      refreshTokenHandler();
    } else {
      dispatch(logOut());
      toast.error(message);
    }
  };

  const getFirstRoutesUserHasPermission = (user, redirectPath) => {
    const permissions = user?.modules?.find(
      (module) => module?.guid === "790af7b9-d9f8-4862-abf7-c0afeef174e8"
    )?.permissions;

    const routePermissions = {
      approvalRules: permissions?.some(
        (permission) => permission?.guid === userPermissions.APPROVAL_RULES
      ),
      approvalRequests: permissions?.some(
        (permission) => permission?.guid === userPermissions.APPROVAL_REQUESTS
      ),
      myInvoices: permissions?.some(
        (permission) => permission?.guid === userPermissions.MY_INVOICES
      ),
      allInvoices: permissions?.some(
        (permission) => permission?.guid === userPermissions.ALL_INVOICES
      ),
    };

    const protectedRoutes = [
      "/my-invoices",
      "/invoice-manager",
      "/approval-requests",
      "/approval-rules",
    ];

    const hasAnyPermission = Object.values(routePermissions).some(Boolean);
    if (!hasAnyPermission) {
      return "/no-permission";
    }

    if (!protectedRoutes.includes(redirectPath)) {
      return redirectPath;
    }

    if (routePermissions.myInvoices && redirectPath === "/my-invoices") {
      return "/my-invoices";
    } else if (routePermissions.allInvoices) {
      return "/invoice-manager";
    } else if (routePermissions.approvalRequests) {
      return "/approval-requests";
    } else if (routePermissions.approvalRules) {
      return "/approval-rules";
    } else return redirectPath;
  };

  return (
    <div className="container-fluid login">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="col-12 col-md-auto col-lg-4 col-xl-4">
          <div className="card shadow-sm mx-auto">
            <div className="card-body mt-5 mx-4 mb-4">
              <div className="text-center mb-5">
                <img
                  src={logo}
                  alt="Intellimorph"
                  className="img-fluid"
                  width={300}
                />
              </div>
              <div className="text-center">
                <small>
                  All rights reserved. {currentYear}{" "}
                  {process.env.REACT_APP_VERSION} {process.env.REACT_APP_ENV}{" "}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
