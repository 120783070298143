import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const approvalRulesFieldsAdapter = createEntityAdapter({
  selectId: (ruleField) => ruleField.guid,
});
const approvalRulesAdapter = createEntityAdapter({
  selectId: (rule) => rule.guid,
});

const approvalRulesFieldsInitialState =
  approvalRulesFieldsAdapter.getInitialState();

const approvalRulesInitialState = approvalRulesAdapter.getInitialState();

export const approvalRulesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApprovalRulesFields: builder.query({
      query: () => `/invoices/approval-rules/fields`,
      transformResponse: (responseData) => {
        const loadedRulesFields = responseData;
        return approvalRulesFieldsAdapter.setAll(
          approvalRulesFieldsInitialState,
          loadedRulesFields
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "ApprovalRulesFields", id: arg }];
        } else {
          return [
            { type: "ApprovalRulesFields", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ApprovalRulesFields", id })),
          ];
        }
      },
    }),
    getApprovalRules: builder.query({
      query: () => `/invoices/approval-rules`,
      transformResponse: (responseData) => {
        const loadedRules = responseData?.map((rule, index) => ({
          ...rule,
          ruleNumber: ++index,
        }));
        return approvalRulesAdapter.setAll(
          approvalRulesInitialState,
          loadedRules
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "ApprovalRules", id: arg }];
        } else {
          return [
            { type: "ApprovalRules", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ApprovalRules", id })),
          ];
        }
      },
    }),
    createRule: builder.mutation({
      query: (data) => {
        return {
          url: `/invoices/approval-rules`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "ApprovalRules", id: "LIST" }],
    }),
    updateRule: builder.mutation({
      query: (data) => {
        return {
          url: `/invoices/approval-rules/${data.guid}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [{ type: "ApprovalRules", id: "LIST" }],
    }),
    deleteRule: builder.mutation({
      query: (guid) => ({
        url: `/invoices/approval-rules/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ApprovalRules", id: "LIST" }],
    }),
  }),
});

export const {
  useGetApprovalRulesFieldsQuery,
  useGetApprovalRulesQuery,
  useCreateRuleMutation,
  useDeleteRuleMutation,
  useUpdateRuleMutation,
} = approvalRulesSlice;
