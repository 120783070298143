import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { format, parseISO } from "date-fns";

const invoiceAdapter = createEntityAdapter({
  selectId: (invoice) => invoice.id,
});

const invoiceDetailAdapter = createEntityAdapter({
  selectId: (invoice) => invoice.guid,
});

const processingStatusesAdapter = createEntityAdapter({
  selectId: (status) => status.id,
});
const reviewStatusesAdapter = createEntityAdapter({
  selectId: (status) => status.id,
});
const reviewTypesAdapter = createEntityAdapter({
  selectId: (status) => status.id,
});

const invoicesInitialState = invoiceAdapter.getInitialState();

const invoiceDetailInitialState = invoiceDetailAdapter.getInitialState();

const processingStatusesInitialState =
  processingStatusesAdapter.getInitialState();
const reviewStatusesInitialState = reviewStatusesAdapter.getInitialState();
const reviewTypesInitialState = reviewStatusesAdapter.getInitialState();

function currencyFormatter(value) {
  if (typeof value !== "number" || isNaN(value)) {
    return "N/A";
  }

  const formattedValue = value.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  return formattedValue;
}

export const invoiceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: ({ Status, Query, Page, PageSize }) =>
        `/invoices/?Query=${Query}&Page=${Page}&PageSize=${PageSize}&${Status}`,
      transformResponse: (responseData) => {
        const loadedInvoices = responseData?.items?.map((item) => {
          const {
            guid,
            data,
            category,
            ocr,
            file,
            createdOn,
            updatedOn,
            createdBy,
            updatedBy,
            processingStatus,
            assignedTo,
            reviewStatus,
          } = item;

          const invoiceValues = {};
          data?.forEach((val) => {
            invoiceValues[val.fieldName.replace(/\s/g, "")] = val.fieldValue;
          });

          const invoiceDate = invoiceValues?.InvoiceDate
            ? format(new Date(invoiceValues?.InvoiceDate), "dd/MM/yyyy")
            : null;

          return {
            type: category?.name,
            id: guid,
            client: invoiceValues.CompanyName ?? "N/A",
            invoiceNumber: invoiceValues.InvoiceNumber ?? "N/A",
            invoiceDate: invoiceDate ? invoiceDate : "N/A",
            orderNumber: invoiceValues.PurchaseOrder ?? "N/A",
            vatNumber: invoiceValues.VatNumber ?? "N/A",
            vatAmount: currencyFormatter(parseFloat(invoiceValues.TaxValue)),
            netAmount: currencyFormatter(parseFloat(invoiceValues.NetAmount)),
            totalAmount: currencyFormatter(
              parseFloat(invoiceValues.TotalAmount)
            ),
            typeId: category?.id,
            reviewStatus: reviewStatus?.name,
            processingStatus: processingStatus?.name,
            processingStatusId: processingStatus?.id,
            reviewStatusId: reviewStatus?.id,
            assignedTo,
            pageSettings: {
              pageCount: responseData?.totalPages,
              pageSize: responseData?.pageSize,
              totalRecordsCount: responseData?.total,
              currentPage: responseData?.page,
            },
          };
        });

        return invoiceAdapter.setAll(invoicesInitialState, loadedInvoices);
      },

      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Invoices", id: "LIST" }];
        } else {
          return [
            { type: "Invoices", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Invoices", id })),
          ];
        }
      },
    }),
    getMyInvoices: builder.query({
      query: ({ Status, Query, Page, PageSize }) =>
        `/invoices/my/?Query=${Query}&Page=${Page}&PageSize=${PageSize}&${Status}`,
      transformResponse: (responseData) => {
        const loadedInvoices = responseData?.items?.map((item) => {
          const {
            guid,
            data,
            category,
            ocr,
            file,
            createdOn,
            updatedOn,
            createdBy,
            updatedBy,
            processingStatus,
            reviewStatus,
          } = item;

          const invoiceValues = {};
          data?.forEach((val) => {
            invoiceValues[val.fieldName.replace(/\s/g, "")] = val.fieldValue;
          });

          const invoiceDate = invoiceValues?.InvoiceDate
            ? format(new Date(invoiceValues?.InvoiceDate), "dd/MM/yyyy")
            : null;

          return {
            type: category?.name,
            id: guid,
            client: invoiceValues.CompanyName ?? "N/A",
            invoiceNumber: invoiceValues.InvoiceNumber ?? "N/A",
            invoiceDate: invoiceDate ? invoiceDate : "N/A",
            orderNumber: invoiceValues.PurchaseOrder ?? "N/A",
            vatNumber: invoiceValues.VatNumber ?? "N/A",
            vatAmount: currencyFormatter(parseFloat(invoiceValues.TaxValue)),
            netAmount: currencyFormatter(parseFloat(invoiceValues.NetAmount)),
            totalAmount: currencyFormatter(
              parseFloat(invoiceValues.TotalAmount)
            ),
            typeId: category?.id,
            reviewStatus: reviewStatus?.name,
            processingStatus: processingStatus?.name,
            processingStatusId: processingStatus?.id,
            reviewStatusId: reviewStatus?.id,
            pageSettings: {
              pageCount: responseData?.totalPages,
              pageSize: responseData?.pageSize,
              totalRecordsCount: responseData?.total,
              currentPage: responseData?.page,
            },
          };
        });

        return invoiceAdapter.setAll(invoicesInitialState, loadedInvoices);
      },

      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "MyInvoices", id: "LIST" }];
        } else {
          return [
            { type: "MyInvoices", id: "LIST" },
            ...result.ids.map((id) => ({ type: "MyInvoices", id })),
          ];
        }
      },
    }),

    getInvoiceByGuid: builder.query({
      query: (guid) => `/invoices/${guid}`,
      transformResponse: (responseData) => {
        const loadedInvoiceDetail = responseData;
        return invoiceDetailAdapter.upsertOne(
          invoiceDetailInitialState,
          loadedInvoiceDetail
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "InvoiceDetail", id: arg, invalidating: true }];
        }
        return [{ type: "InvoiceDetail", id: arg }];
      },
    }),

    getProcessingStatuses: builder.query({
      query: () => `/invoices/processing-statuses`,
      transformResponse: (responseData) => {
        const loadedStatuses = responseData;
        return processingStatusesAdapter.setAll(
          processingStatusesInitialState,
          loadedStatuses
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "ProcessingStatuses", id: arg }];
        } else {
          return [
            { type: "ProcessingStatuses", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ProcessingStatuses", id })),
          ];
        }
      },
    }),
    getReviewStatuses: builder.query({
      query: () => `/invoices/review-statuses`,
      transformResponse: (responseData) => {
        const loadedStatuses = responseData;
        return reviewStatusesAdapter.setAll(
          reviewStatusesInitialState,
          loadedStatuses
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "ReviewStatuses", id: arg }];
        } else {
          return [
            { type: "ReviewStatuses", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ReviewStatuses", id })),
          ];
        }
      },
    }),
    getReviewTypes: builder.query({
      query: () => `/invoices/review-types`,
      transformResponse: (responseData) => {
        const loadedStatuses = responseData;
        return reviewTypesAdapter.setAll(
          reviewTypesInitialState,
          loadedStatuses
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "ReviewTypes", id: arg }];
        } else {
          return [
            { type: "ReviewTypes", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ReviewTypes", id })),
          ];
        }
      },
    }),

    getInvoiceInsights: builder.query({
      query: () => `/insights/invoice`,
      providesTags: (result, error, arg) => [
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
      ],
    }),

    getMyInvoiceInsights: builder.query({
      query: () => `/insights/invoice/my`,
      providesTags: (result, error, arg) => [
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
      ],
    }),

    getAudits: builder.query({
      query: ({ invoiceGuid, userGuid, query, page, pageSize }) =>
        `/audits?invoiceGuid=${invoiceGuid}&userGuid=${userGuid}&query=${query}&page=${page}&pageSize=${pageSize}`,
      providesTags: (result, error, arg) => [
        { type: "Audits", id: "DEFAULT_ID" },
      ],
    }),

    createInvoice: builder.mutation({
      query: (data) => {
        return {
          url: `/invoices`,
          method: "POST",
          formData: true,
          body: data,
        };
      },
      invalidatesTags: [{ type: "Invoices", id: "LIST" }],
    }),

    updateInvoice: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/invoices/${guid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InvoiceDetail", id: arg.guid },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),

    updateInvoiceClient: builder.mutation({
      query: (data) => ({
        url: `/invoices/${data?.guid}/client`,
        method: "PUT",
        body: {
          clientName: data?.clientName,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InvoiceDetail", id: arg.guid },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" },
      ],
    }),

    resubmitInvoice: builder.mutation({
      query: ({ guid }) => ({
        url: `/invoices/${guid}/status`,
        method: "PUT",
        body: {
          processingStatusId: 2,
          reviewStatusId: 4,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InvoiceDetail", id: arg.guid },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),

    assignInvoice: builder.mutation({
      query: ({ guid, userGuid }) => ({
        url: `/invoices/${guid}:assign-to`,
        method: "POST",
        body: {
          userGuid,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InvoiceDetail", id: arg.guid },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" },
      ],
    }),

    deleteInvoice: builder.mutation({
      query: (guid) => ({
        url: `/invoices/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),
    approveInvoice: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/invoices/${guid}:approve`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "InvoiceDetail" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),
    rejectInvoice: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/invoices/${guid}:reject`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "InvoiceDetail" },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),

    completeManualReview: builder.mutation({
      query: (guid) => ({
        url: `/invoices/${guid}:complete-manual-review`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InvoiceDetail", id: arg },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),
    updateInvoiceStatus: builder.mutation({
      query: ({ guid, processingStatusId, reviewStatusId }) => ({
        url: `/invoices/${guid}/status`,
        method: "PUT",
        body: {
          guid,
          processingStatusId,
          reviewStatusId,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InvoiceDetail", id: arg },
        { type: "Invoices", id: "LIST" },
        { type: "MyInvoices", id: "LIST" },
        { type: "InvoiceInsights", id: "DEFAULT_ID" },
        { type: "MyInvoiceInsights", id: "DEFAULT_ID" },
        { type: "Audits", id: "DEFAULT_ID" }
      ],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceByGuidQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useResubmitInvoiceMutation,
  useApproveInvoiceMutation,
  useDeleteInvoiceMutation,
  useRejectInvoiceMutation,
  useGetProcessingStatusesQuery,
  useGetReviewStatusesQuery,
  useGetReviewTypesQuery,
  useCompleteManualReviewMutation,
  useGetInvoiceInsightsQuery,
  useGetMyInvoiceInsightsQuery,
  useGetMyInvoicesQuery,
  useAssignInvoiceMutation,
  useUpdateInvoiceStatusMutation,
  useUpdateInvoiceClientMutation,
  useGetAuditsQuery,
} = invoiceSlice;
