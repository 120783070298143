import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/images/Icons/searchwhite.svg";
import closeIcon from "../../assets/images/Icons/closeIconwhite.svg";
import "./TableSearch.css";
import { Spinner } from "reactstrap";

const TableSearch = ({
  pageNumber,
  setPageNumber,
  setQuery,
  isLoading,
  pageName,
}) => {
  const [searchText, setSearchText] = useState(
    localStorage.getItem(`${pageName}SearchQuery`) || ""
  );
  const [isClearVisible, setIsClearVisible] = useState(false);

  useEffect(() => {
    if (searchText !== "") {
      setIsClearVisible(true);
    } else {
      setIsClearVisible(false);
    }
  }, [searchText]);

  const handleSearch = () => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }

    setQuery(searchText);
    localStorage.setItem(`${pageName}SearchQuery`, searchText);
  };

  const handleClearSearch = () => {
    setIsClearVisible(false);
    setSearchText("");
    setQuery("");
    localStorage.removeItem(`${pageName}SearchQuery`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="input-group table-search">
      <input
        id="invoiceSearch"
        type="text"
        className="form-control invoice-search"
        placeholder="Search Invoice"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        onKeyDown={handleKeyDown}
      />
      <label
        htmlFor="invoiceSearch"
        className="input-group-text search-icon-container"
      >
        {isClearVisible && (
          <button className="btn btn-search close" onClick={handleClearSearch}>
            <img
              src={closeIcon}
              alt="Close Icon"
              className="icon-image"
              width={16}
            />
          </button>
        )}
        <button className="btn btn-search" onClick={handleSearch}>
          {isLoading ? (
            <Spinner color="light" size={"sm"} />
          ) : (
            <img
              src={searchIcon}
              alt="Search Icon"
              className="icon-image"
              width={16}
            />
          )}
        </button>
      </label>
    </div>
  );
};

export default TableSearch;
