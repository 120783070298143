import React, { memo, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = memo(({ isOpen, toggle, action, message, type }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={"static"}
      centered
      size="md"
    >
      <ModalHeader>{message}</ModalHeader>
      <ModalFooter className="gap-1 justify-content-between">
        <Button
          color="btn btn-grey btn-sm m-0"
          onClick={toggle}
          style={{ width: "49%" }}
        >
          Cancel
        </Button>
        <Button
          color="btn btn-blue btn-sm m-0"
          onClick={action}
          style={{ width: "49%" }}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ConfirmationModal;
