import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Inject,
  Page,
  PagerComponent,
} from "@syncfusion/ej2-react-grids";
import { L10n } from "@syncfusion/ej2-base";
import "./DataTable.css";
import { useLocation, useNavigate } from "react-router-dom";

L10n.load({
  "en-US": {
    pager: {
      itemsPerPage: "",
      currentPageInfo: "",
      totalItemsInfo: "Showing {1} to {2} of {0}",
    },
  },
});

const DataTable = ({
  data,
  columns,
  handlePageChange,
  currentPage,
  height = 745,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handlePageClick = (args) => {
    navigate(`${pathname}?page=${args.currentPage}`);
    handlePageChange(args.currentPage);
  };

  const tableColumns = columns.map((column) => (
    <ColumnDirective
      key={column.field}
      field={column.field}
      headerText={column.headerText}
      width={column.width}
      textAlign={column.alignment}
      template={column.template}
    />
  ));

  return (
    <>
      <GridComponent
        dataSource={data}
        allowPaging={false}
        allowTextWrap={true}
        height={height}
        pageSettings={{ pageCount: 4, pageSizes: true }}
        locale="en-US"
        loadingIndicator={{ indicatorType: "Shimmer" }}
      >
        <ColumnsDirective>{tableColumns}</ColumnsDirective>
        <Inject services={[CommandColumn, Page]} />
      </GridComponent>
      <PagerComponent
        // pageCount={data[0]?.pageSettings?.pageCount}
        pageSize={data[0]?.pageSettings?.pageSize}
        currentPage={currentPage}
        totalRecordsCount={data[0]?.pageSettings?.totalRecordsCount}
        click={handlePageClick}
      />
    </>
  );
};
export default DataTable;
