import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../features/auth/authSlice";

export const useGetUserHasPermission = () => {
  const permissions = useSelector(selectCurrentPermissions);
  const getUserHasPermission = (requiredPermission) => {
    const hasPermission = permissions?.some(
      (permission) => permission.guid === requiredPermission
    );
    return hasPermission;
  };
  return getUserHasPermission;
};
