import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/dashboard/Dashboard";
import InvoiceManager from "./features/InvoiceManager/InvoiceManager";
import RoleManagement from "./features/RoleManagement/RoleManagement";
import Reports from "./features/Reports/Reports";
import EditInvoice from "./components/InvoiceManager/EditInvoice/EditInvoice";
import ApprovalRules from "./features/ApprovalRules/ApprovalRules";
import ApprovalRequests from "./features/ApprovalRequests/ApprovalRequests";
import LoginPage from "./pages/LoginPage/LoginPage";
import RequireAuth from "./features/auth/RequireAuth";
import FailedInvoices from "./features/FailedInvoices/FailedInvoices";
import MyInvoices from "./features/MyInvoices/MyInvoices";
import InQueryInvoices from "./features/InQueryInvoices/InQueryInvoices";
import ViewInvoice from "./features/ViewInvoice/ViewInvoice";
import NoPermisson from "./pages/NoPermission/NoPermisson";
import ProtectedRoute from "./features/auth/ProtectedRoute";
import NotFound from "./pages/NotFound/NotFound";
import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "./features/auth/authSlice";
import { getIndexPath } from "./components/common/utils/utils";
import { userPermissions } from "./enums/userPermissons";

function App() {
  const permissions = useSelector(selectCurrentPermissions);
  const indexPath = getIndexPath(permissions);

  return (
    <Routes>
      <Route index path="/login" element={<LoginPage />} />
      <Route path="/no-permission" element={<NoPermisson />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Navigate to={indexPath} />} />
          {permissions?.some(
            (permission) => permission.guid === userPermissions.MY_INVOICES
          ) && (
            <Route
              path="my-invoices"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.MY_INVOICES}
                >
                  <MyInvoices />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.ALL_INVOICES
          ) && (
            <Route
              path="invoice-manager"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.ALL_INVOICES}
                >
                  <InvoiceManager />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.ALL_INVOICES
          ) && (
            <Route
              path="inQuery-invoices"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.ALL_INVOICES}
                >
                  <InQueryInvoices />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.ALL_INVOICES
          ) && (
            <Route
              path="failed-invoices"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.ALL_INVOICES}
                >
                  <FailedInvoices />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.INVOICE_DETAILS
          ) && (
            <Route
              path="invoice/view/:id"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.INVOICE_DETAILS}
                >
                  <ViewInvoice />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.UPDATE_INVOICE
          ) && (
            <Route
              path="invoice/edit/:id"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.UPDATE_INVOICE}
                >
                  <EditInvoice />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.ALL_INVOICES
          ) && (
            <Route
              path="role-management"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.ALL_INVOICES}
                >
                  <RoleManagement />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.ALL_INVOICES
          ) && (
            <Route
              path="reports"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.ALL_INVOICES}
                >
                  <Reports />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) => permission.guid === userPermissions.APPROVAL_RULES
          ) && (
            <Route
              path="approval-rules"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.APPROVAL_RULES}
                >
                  <ApprovalRules />
                </ProtectedRoute>
              }
            />
          )}
          {permissions?.some(
            (permission) =>
              permission.guid === userPermissions.APPROVAL_REQUESTS
          ) && (
            <Route
              path="approval-requests"
              element={
                <ProtectedRoute
                  requiredPermission={userPermissions.APPROVAL_REQUESTS}
                >
                  <ApprovalRequests />
                </ProtectedRoute>
              }
            />
          )}
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to={"not-found"} />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
