import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100 bg-light"
      style={{ height: "100%" }}
    >
      <div className="text-center">
        <div>
          <h1 className="display-1 text-danger">404</h1>
          <h2 className="mb-4">Page Not Found</h2>
          <p className="mb-4">
            The page you are looking for doesn't exist or has been moved.
          </p>
          {/* <button className="btn btn-blue" onClick={handleGoHome}>
            Go to Home
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
