import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { logOut } from "../features/auth/authSlice";

let hasShown500ErrorToast = false;

export const useErrorToast = () => {
  const dispatch = useDispatch();

  const showErrorToast = (error) => {
    if (error?.status === 500 || error?.status === "FETCH_ERROR") {
      if (!hasShown500ErrorToast) {
        hasShown500ErrorToast = true;
        toast.error(
          (t) => (
            <span>
              Looks like there is a Server Error. Please Log out and Log in
              again..!
              <br />
              <button
                style={{ float: "right" }}
                className="btn btn-blue btn-sm ms-1"
                onClick={() => {
                  dispatch(logOut());
                  hasShown500ErrorToast = false;
                }}
              >
                Logout
              </button>
              <button
                style={{ float: "right" }}
                className="btn btn-grey btn-sm"
                onClick={() => {
                  toast.dismiss(t.id);
                  hasShown500ErrorToast = false;
                }}
              >
                Close
              </button>
            </span>
          ),
          {
            duration: 20000,
          }
        );
      }
    } else {
      toast.error(
        (t) => (
          <span>
            {error?.data?.Message ||
              error?.data?.title ||
              error?.error ||
              "Something Unexpected Happened.  Please Log out and Log in again..!"}
          </span>
        ),
        {
          duration: 2000,
        }
      );
    }
  };

  return showErrorToast;
};
