import React from "react";
import EditInvoice from "../../components/InvoiceManager/EditInvoice/EditInvoice";
import { useParams } from "react-router-dom";

const ViewInvoice = () => {
  const params = useParams();
  return <EditInvoice viewOnly={true} id={params?.id} />;
};

export default ViewInvoice;
