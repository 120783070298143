import React, { useEffect, useState } from "react";
import lockIcon from "../../../assets/images/Icons/lockIcon.svg";
import editIcon from "../../../assets/images/Icons/edit.png";
import deleteIcon from "../../../assets/images/Icons/delete.svg";
import toast, { Toaster } from "react-hot-toast";
import {
  useCreateRuleMutation,
  useDeleteRuleMutation,
  useUpdateRuleMutation,
} from "../../../features/ApprovalRules/approvalRulesSlice";
import AlertModal from "../../common/AlertModal/AlertModal";
import LoadingSpinner from "../../common/LoadingSpinner/LoadingSpinner";
import { useErrorToast } from "../../../hooks/useErrorToast";

const Rule = ({ rule, columns, ruleNumber, removeRule }) => {
  const [
    createRule,
    {
      isLoading: createRuleIsLoading,
      isSuccess: createRuleIsSuccess,
      isError: createRuleIsError,
      error: createRuleError,
    },
  ] = useCreateRuleMutation();
  const [
    deleteRule,
    {
      isLoading: deleteRuleIsLoading,
      isSuccess: deleteRuleIsSuccess,
      isError: deleteRuleIsError,
      error: deleteRuleError,
    },
  ] = useDeleteRuleMutation();
  const [
    updateRule,
    {
      isLoading: updateRuleIsLoading,
      isSuccess: updateRuleIsSuccess,
      isError: updateRuleIsError,
      error: updateRuleError,
    },
  ] = useUpdateRuleMutation();

  const [editable, setEditable] = useState(rule?.isNew ? true : false);

  const [columnValue, setColumnValue] = useState(rule.field.id);
  const [conditionValue, setConditionValue] = useState(rule.condition);
  const [value, setValue] = useState(
    rule.field.type === "datetime" ? new Date(rule.value) : rule.value
  );
  const [selectedColumn, setSelectedColumn] = useState(
    columns.find((column) => column.id === rule.field.id)
  );
  const [showAlert, setShowAlert] = useState(false);
  const [ruleGuid, setRuleGuid] = useState(0);
  const [description, setDescription] = useState(rule.description);
  const [originalRule, setOriginalRule] = useState(null);
  const showErrorToast = useErrorToast();

  useEffect(() => {
    const column = columns.find((column) => column.id === columnValue);
    setSelectedColumn(column);
  }, [columnValue]);

  const handleEdit = () => {
    setEditable(true);
    setOriginalRule(rule);
  };

  const handleCancel = (data) => {
    setEditable(false);
    if (data.isNew) {
      removeRule(data.guid);
      return;
    }
    setColumnValue(rule.field.id);
    setConditionValue(rule.condition);
    setValue(
      rule.field.type === "datetime" ? new Date(rule.value) : rule.value
    );
    setSelectedColumn(columns.find((column) => column.id === rule.field.id));
    setDescription(rule.description);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (rule.isNew) {
      try {
        const response = await createRule({
          description,
          fieldId: columnValue,
          condition: conditionValue,
          value,
        }).unwrap();
        toast.success("New Rule Created");
        setEditable(false);
      } catch (error) {
        showErrorToast(error);
      }
    } else {
      try {
        const response = await updateRule({
          guid: rule.guid,
          description,
          fieldId: columnValue,
          condition: conditionValue,
          value,
        }).unwrap();
        setEditable(false);
        toast.success("Rule Updated");
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  const handleConfirmation = async (state) => {
    setShowAlert((prev) => !prev);
    if (state) {
      try {
        const response = await deleteRule(ruleGuid);
        toast.success("Rule Deleted");
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  const handleDeleteRule = (id) => {
    setShowAlert(true);
    setRuleGuid(id);
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {createRuleIsLoading && <LoadingSpinner />}
      {deleteRuleIsLoading && <LoadingSpinner />}
      {updateRuleIsLoading && <LoadingSpinner />}
      <form onSubmit={handleSave}>
        <div className="py-3 border-top">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col-9">
              {rule.isNew || editable ? (
                <div className="condition-container description">
                  <label htmlFor="">Description</label>
                  <input
                    className="condition-field"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={!editable}
                    required
                    placeholder="Rule Description"
                  />
                </div>
              ) : (
                <h6>
                  {ruleNumber} {"."} {rule.description}
                </h6>
              )}
            </div>
            <div className="col-auto">
              <div className="btn-group">
                {/* <button className="btn btn-sm" type="button">
                  <img src={lockIcon} width={32} alt="" />
                </button> */}
                <button
                  className="btn btn-sm"
                  type="button"
                  onClick={handleEdit}
                >
                  <img src={editIcon} width={32} alt="" />
                </button>
                {!rule.isNew && (
                  <button
                    className="btn btn-sm"
                    type="button"
                    onClick={() => handleDeleteRule(rule.guid)}
                  >
                    <img src={deleteIcon} width={32} alt="" />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="row g-3 mb-2">
            <div className="col-auto">
              <h5 className="fw-bold">If</h5>
            </div>
            <div className="col-3">
              <div className="condition-container">
                <label htmlFor="">TABLE COLUMN</label>
                <select
                  className="condition-field"
                  value={columnValue}
                  onChange={(e) =>
                    setColumnValue(
                      e.target.value !== "" ? parseInt(e.target.value) : ""
                    )
                  }
                  disabled={!editable}
                  required
                >
                  <option value={""}>Select the column </option>
                  {columns.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {columnValue !== "" && (
              <>
                <div className="col-3">
                  <div className="condition-container">
                    <label htmlFor="">CONDITION</label>
                    <select
                      className="condition-field"
                      value={conditionValue}
                      onChange={(e) => setConditionValue(e.target.value)}
                      disabled={!editable}
                      required
                    >
                      {selectedColumn &&
                        selectedColumn.allowedConditions.map((operator) => (
                          <option key={operator.id} value={operator.id}>
                            {operator.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="condition-container">
                    <label htmlFor="">VALUE</label>

                    {selectedColumn?.type === "datetime" ? (
                      <input
                        className="condition-field"
                        type="date"
                        value={
                          value
                            ? new Date(value).toISOString().split("T")[0]
                            : ""
                        }
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!editable}
                        required
                      />
                    ) : selectedColumn?.type?.split("|").includes("select") ? (
                      <select
                        className="condition-field"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!editable}
                        required
                      >
                        {JSON.parse(selectedColumn?.type?.split("|")[1]).map(
                          (value, index) => (
                            <option key={index} value={value.id}>
                              {value.name}
                            </option>
                          )
                        )}
                      </select>
                    ) : (
                      <input
                        className="condition-field"
                        type={
                          selectedColumn?.type === "number" ? "number" : "text"
                        }
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!editable}
                        required
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          {editable && (
            <div className="d-flex justify-content-end">
              <div>
                <button className="btn btn-blue me-2" type="submit">
                  Save
                </button>
                <button
                  className="btn btn-grey"
                  type="button"
                  onClick={() => handleCancel(rule)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
      <AlertModal isOpen={showAlert} toggle={handleConfirmation} />
    </>
  );
};

export default Rule;
