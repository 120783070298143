import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const AlertModal = ({ isOpen, toggle }) => {
  const [confirmed, setConfirmed] = useState(false);

  const handleYes = () => {
    setConfirmed(true);
    toggle(true);
  };

  const handleNo = () => {
    setConfirmed(false);
    toggle(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>This action cannot be undone.</ModalBody>
      <ModalFooter>
        <Button color="btn btn-light" onClick={handleNo}>
          Cancel
        </Button>
        <Button color="btn btn-danger" onClick={handleYes}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AlertModal;
