import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { format, parseISO } from "date-fns";

const commentsAdapter = createEntityAdapter({
  selectId: (comment) => comment.guid,
});

const commentsInitialState = commentsAdapter.getInitialState();

export const commentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query({
      query: (guid) => `/invoices/${guid}/comments`,
      transformResponse: (responseData) => {
        const loadedComments = responseData?.map((d) => ({
          ...d,
          createdOn: format(new Date(d?.createdOn), "dd/MM/yyyy hh:mm:ss a"),
        }));
        return commentsAdapter.setAll(commentsInitialState, loadedComments);
      },

      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Comments", id: "LIST" }];
        } else {
          return [
            { type: "Comments", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Comments", id })),
          ];
        }
      },
    }),

    addComment: builder.mutation({
      query: ({ guid, comment }) => ({
        url: `/invoices/${guid}/comments`,
        method: "POST",
        body: {
          comment,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Comments", id: "LIST" },
      ],
    }),

    updateComment: builder.mutation({
      query: ({ guid, comment }) => ({
        url: `/invoices/comments/${guid}`,
        method: "PUT",
        body: {
          comment,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Comments", id: "LIST" },
      ],
    }),

    deleteComment: builder.mutation({
      query: (guid) => ({
        url: `/invoices/comments/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Comments", id: "LIST" }],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} = commentsSlice;
