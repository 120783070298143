import { userPermissions } from "../../../enums/userPermissons";

export function currencyFormatter(value) {
  if (typeof value !== "number" || isNaN(value)) {
    return "N/A";
  }

  const formattedValue = value.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  return formattedValue;
}

export function convertToReadable(key) {
  return key.replace(/([A-Z])/g, " $1").trim();
}

export const getAuthURL = () => {
  return window.location.hostname === "localhost"
    ? `https://devintellimorph.${process.env.REACT_APP_AUTH_URL}`
    : `${window.location.protocol}//${window.location.hostname.split(".")[0]}.${
        process.env.REACT_APP_AUTH_URL
      }`;
};

export const getIndexPath = (permissions) => {
  const routePermissions = {
    approvalRules: permissions?.some(
      (permission) => permission?.guid === userPermissions.APPROVAL_RULES
    ),
    approvalRequests: permissions?.some(
      (permission) => permission?.guid === userPermissions.APPROVAL_REQUESTS
    ),
    myInvoices: permissions?.some(
      (permission) => permission?.guid === userPermissions.MY_INVOICES
    ),
    allInvoices: permissions?.some(
      (permission) => permission?.guid === userPermissions.ALL_INVOICES
    ),
  };

  const hasAnyPermission = Object.values(routePermissions).some(Boolean);
  if (!hasAnyPermission) {
    return "/no-permission";
  }

  if (routePermissions.myInvoices) {
    return "/my-invoices";
  } else if (routePermissions.allInvoices) {
    return "/invoice-manager";
  } else if (routePermissions.approvalRequests) {
    return "/approval-requests";
  } else if (routePermissions.approvalRules) {
    return "/approval-rules";
  } else return "/not-found";
};

export const getBaseUrl = () => {
  return `${
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_API_URL
      : process.env.PUBLIC_URL
  }/v1/`;
};
