// src/features/auth/ProtectedRoute.js
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectCurrentPermissions } from "./authSlice";

const ProtectedRoute = ({ children, requiredPermission }) => {
  const permissions = useSelector(selectCurrentPermissions);

  const hasPermission = permissions?.some(
    (permission) => permission.guid === requiredPermission
  );

  if (!hasPermission) {
    return <Navigate to="/no-permission" replace />;
  }

  return children;
};

export default ProtectedRoute;
