import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import expandIcon from "../../assets/images/expand.svg";
import collapseIcon from "../../assets/images/collapse.svg";
import "./Dashboard.css";

const Dashboard = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={`layout ${!isSidebarCollapsed ? "collapsed" : ""}`}>
      <div className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <SideBar
          toggleSidebar={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
      </div>
      <div className="main">
        <Outlet />
      </div>
      <button
        className="btn-menu mobile"
        onClick={toggleSidebar}
        title="collapse menu"
      >
        <img
          src={isSidebarCollapsed ? collapseIcon : expandIcon}
          width={18}
          alt=""
        />
      </button>
    </div>
  );
};

export default Dashboard;
