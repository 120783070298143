import React, { useState, useEffect } from "react";
import "./InvoiceInsights.css";
import { useGetInvoiceInsightsQuery } from "../../../features/InvoiceManager/invoiceSlice";

const InvoiceInsights = () => {
  const { data: invoiceInsights } = useGetInvoiceInsightsQuery();
  const [insights, setInsights] = useState("");
  useEffect(() => {
    if (invoiceInsights) {
      setInsights(invoiceInsights);
    }
  }, [invoiceInsights]);
  return (
    <div className="stats">
      <div className="row g-1 align-items-center justify-content-between mb-2">
        <div className="col-sm-6 col-md align-self-stretch">
          <div className="custom-card stat-details-container review-required-container">
            <p className="stat-title">REVIEW REQUIRED</p>
            <h4 className="stat-value">{insights.reviewRequiredCount}</h4>
          </div>
        </div>
        <div className="col-sm-6 col-md align-self-stretch">
          <div className="custom-card stat-details-container approval-required-container">
            <p className="stat-title">APPROVAL REQUIRED</p>
            <h4 className="stat-value">{insights.approvalRequiredCount}</h4>
          </div>
        </div>
        <div className="col-sm-6 col-md align-self-stretch">
          <div className="custom-card stat-details-container ready-for-processing-container">
            <p className="stat-title ">Ready For processing</p>
            <h4 className="stat-value">{insights.readyForProcessingCount}</h4>
          </div>
        </div>
        <div className="col-sm-6 col-md align-self-stretch">
          <div className="custom-card stat-details-container successful-container">
            <p className="stat-title">SUCCESSFUL</p>
            <h4 className="stat-value">{insights.successfulCount}</h4>
          </div>
        </div>
        <div className="col-sm-6 col-md align-self-stretch">
          <div className="custom-card stat-details-container failed-container">
            <p className="stat-title">FAILED</p>
            <h4 className="stat-value">{insights.failedCount}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceInsights;
