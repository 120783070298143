export const ReviewStatus = {
  NEW: 1,
  DATA_EXTRACTED: 2,
  AI_APPROVED: 3,
  REVIEW_REQUIRED: 4,
  REVIEWED: 5,
  APPROVAL_REQUIRED: 6,
  APPROVED: 7,
  REJECTED: 8,
};

export const ProcessingStatus = {
  NEW: 1,
  UNDER_REVIEW: 2,
  READY_FOR_PROCESSING: 3,
  PROCESSING: 4,
  SUCCESSFUL: 5,
  FAILED: 6,
  IN_QUERY: 7,
};
