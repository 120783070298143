import React, { useEffect } from "react";
import "./SideBar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import invoiceManagerIcon from "../../assets/images/Icons/navIcons/invoiceIcon.svg";
import roleManagementIcon from "../../assets/images/Icons/Role Manager.png";
import approvalRequestsIcon from "../../assets/images/Icons/navIcons/approvalRequestsIcon.svg";
import closeIcon from "../../assets/images/Icons/closeIcon.svg";
import logoutIcon from "../../assets/images/Icons/logout.svg";
import approvalRulesIcon from "../../assets/images/Icons/navIcons/approvalRulesIcon.svg";
import failedInvoicesIcon from "../../assets/images/Icons/navIcons/failedInvoicesIcon.svg";
import myInvoicesIcon from "../../assets/images/Icons/navIcons/myInvoiceIcon.svg";
import inQueryInvoicesIcon from "../../assets/images/Icons/navIcons/inQueryInvoicesIcon.svg";
import expandIcon from "../../assets/images/expand.svg";
import collapseIcon from "../../assets/images/collapse.svg";
import {
  selectCurrentPermissions,
  selectCurrentUser,
  logOut,
} from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { getAuthURL } from "../common/utils/utils";

const SideBar = ({ toggleSidebar, isSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const permissions = useSelector(selectCurrentPermissions);
  const navigate = useNavigate();

  const authURL = getAuthURL();

  const handleLogout = () => {
    window.history.replaceState(null, null, window.location.pathname);
    dispatch(logOut());
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);

  const hasPermissionForApprovalRules = permissions?.some(
    (permission) => permission.guid === "9bea8b16-07fa-4f59-898a-712af7e742d4"
  );
  const hasPermissionForApprovalRequests = permissions?.some(
    (permission) => permission.guid === "ff8426ea-3130-4732-bd7c-be28ae759cd3"
  );

  const hasPermissionToMyInvoices = permissions?.some(
    (permission) => permission?.guid === "339add39-b3f9-4089-8f47-b5d84996f536"
  );

  const hasPermissionToAllInvoices = permissions?.some(
    (permission) => permission?.guid === "143bd534-f4a3-4825-9f65-dbfb8360ddbb"
  );

  return (
    <div className="custom-card sidebar-container">
      <div>
        <button
          className="btn btn-collapse close"
          onClick={toggleSidebar}
          style={{ alignSelf: "end" }}
        >
          <img src={closeIcon} width={20} alt="" />
        </button>

        <h5 className="nav-brand text-center">
          <img src={logo} alt="" />
        </h5>
        <div className="list-group list-group-flush scrollarea menu-link ">
          {hasPermissionToMyInvoices && (
            <NavLink
              to="my-invoices"
              className={`list-group-item list-group-item-action mb-2 lh-sm rounded d-flex align-items-center nav-link`}
            >
              <img className="me-2 nav-icon" src={myInvoicesIcon} alt="" />
              <span className="nav-text">My Invoices</span>
            </NavLink>
          )}

          {hasPermissionToAllInvoices && (
            <NavLink
              to="invoice-manager"
              className={`list-group-item list-group-item-action mb-2 lh-sm rounded d-flex align-items-center nav-link`}
            >
              <img className="me-2 nav-icon" src={invoiceManagerIcon} alt="" />
              <span className="nav-text">Invoices</span>
            </NavLink>
          )}

          {hasPermissionForApprovalRequests && (
            <NavLink
              to="approval-requests"
              className={`list-group-item list-group-item-action mb-2 lh-sm rounded d-flex align-items-center nav-link`}
            >
              <img
                className="me-2 nav-icon"
                src={approvalRequestsIcon}
                alt=""
              />
              <span className="nav-text">Approval Requests</span>
            </NavLink>
          )}

          {hasPermissionToAllInvoices && (
            <>
              <NavLink
                to="inQuery-invoices"
                className={`list-group-item list-group-item-action mb-2 lh-sm rounded d-flex align-items-center nav-link`}
              >
                <img
                  className="me-2 nav-icon"
                  src={inQueryInvoicesIcon}
                  alt=""
                />
                <span className="nav-text">In Query Invoices</span>
              </NavLink>
              <NavLink
                to="failed-invoices"
                className={`list-group-item list-group-item-action mb-2 lh-sm rounded d-flex align-items-center nav-link`}
              >
                <img
                  className="me-2 nav-icon"
                  src={failedInvoicesIcon}
                  alt=""
                />
                <span className="nav-text">Failed Invoices</span>
              </NavLink>
            </>
          )}

          {hasPermissionForApprovalRules && (
            <NavLink
              to="approval-rules"
              className={`list-group-item list-group-item-action mb-2 lh-sm rounded d-flex align-items-center nav-link`}
            >
              <img className="me-2 nav-icon" src={approvalRulesIcon} alt="" />
              <span className="nav-text">Approval Rules</span>
            </NavLink>
          )}
        </div>
      </div>
      <div className="avatar-dropdown-container">
        <button
          className="btn-menu desktop"
          onClick={toggleSidebar}
          title="collapse menu"
        >
          <span className="btn-menu-text"> Collapse Menu</span>
          <img
            src={isSidebarCollapsed ? collapseIcon : expandIcon}
            width={18}
            alt=""
          />
        </button>
        <div className="btn-group user-details-container">
          <Link
            to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user?.guid}`}
            target="_blank"
          >
            <div className="avatar-container">
              <img
                src={`${authURL}/v1/companies/users/${user?.guid}/profile-picture`}
                alt="Avatar"
                className="avatar-img"
              />
            </div>
          </Link>
          <div className="nav-text nav-text-below">
            <Link
              to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user?.guid}`}
              target="_blank"
            >
              <p className="fw-bold m-0 user-name">
                {user?.firstName} {user?.lastName}
              </p>
            </Link>
            <span className="role-text">{user?.roles[0]?.description}</span>
          </div>
          <button
            type="button"
            className="btn btn-user nav-text"
            onClick={handleLogout}
            title="logout"
          >
            <img src={logoutIcon} width={18} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
