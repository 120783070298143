import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./ApproveOrRejectInvoice.css";
import PDFViewer from "../../PDFViewer/PDFViewer";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Toaster } from "react-hot-toast";

const ApproveOrRejectInvoice = ({
  modal,
  toggle,
  action,
  onClickFunction,
  setComment,
  setReason,
}) => {
  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  };
  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
    link: ["Open", "Edit", "UnLink"],
  };

  let rteObj;
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [comment, setCommentValue] = useState("");

  const handleApproveOrReject = (value) => {
    if (value === "approve") {
      setIsApprove(true);
    }

    if (value === "reject") {
      setIsApprove(false);
    }

    toggle();

    setNewModalOpen(true);
  };

  const handleReset = () => {
    setNewModalOpen(false);
  };

  const handleNewModalClose = () => {
    setNewModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onClickFunction) {
      onClickFunction();
    }
    handleReset();
  };

  const handleCancel = () => {
    handleReset();
    handleNewModalClose();
  };

  useEffect(() => {
    if (action === "approve") {
      handleApproveOrReject("approve");
    }
    if (action === "reject") {
      handleApproveOrReject("reject");
    }
  }, [action]);

  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody>
          <PDFViewer
            path={"https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf"}
            height="84vh"
          />
        </ModalBody>
        <ModalFooter className="border-0">
          <Button
            onClick={() => handleApproveOrReject("approve")}
            className="btn-approve"
          >
            Approve
          </Button>
          <Button
            onClick={() => handleApproveOrReject("reject")}
            className="btn-reject"
          >
            Reject
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={newModalOpen}
        toggle={handleNewModalClose}
        size="md"
        centered
      >
        <ModalHeader className="border-0">
          {isApprove ? (
            <p className="approve-title">
              {action === "" ? `Approval` : `The Invoice: ${1234} Approval`}
            </p>
          ) : (
            <p className="reject-title">
              {action === "" ? `Reject` : `The Invoice: ${1234} Approval`}
            </p>
          )}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            {!isApprove ? (
              <div className="mb-2">
                <label htmlFor="rejectReason" className="edit-title">
                  Reason for Rejection
                </label>
                <input
                  id="rejectReason"
                  type="text"
                  className="form-control invoice-search form-control-sm"
                  placeholder="Reject Reason"
                  // defaultValue={rejectReason}`
                  onChange={(e) => {
                    const updatedRejectReason = e.target.value;
                    setRejectReason(updatedRejectReason);
                    setReason(updatedRejectReason);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className="mb-2">
              <label htmlFor="comments" className="edit-title">
                Comments/Notes
              </label>
              <RichTextEditorComponent
                id="comments"
                height={200}
                toolbarSettings={toolbarSettings}
                quickToolbarSettings={quickToolbarSettings}
                ref={(richtexteditor) => (rteObj = richtexteditor)}
                change={(args) => {
                  let updatedValue = args.value;
                  const value = updatedValue.replace(/<\/?[^>]+(>|$)/g, "");
                  setCommentValue(value);
                  setComment(value);
                }}
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <button
                    type="reset"
                    className="btn btn-reset cancel me-2 btn-sm"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-filter btn-sm">
                    {isApprove ? "Approve" : "Reject"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ApproveOrRejectInvoice;
